package ru.workinprogress.about.components.layouts

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.css.Width
import com.varabyte.kobweb.compose.dom.svg.*
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toAttrs
import kotlinx.browser.document
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.fr
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import ru.workinprogress.about.components.sections.Footer
import ru.workinprogress.about.components.sections.NavHeader

val PageContentStyle = CssStyle {
    base { Modifier.fillMaxSize().padding(leftRight = 2.cssRem, top = 4.cssRem) }
    Breakpoint.MD { Modifier.maxWidth(60.cssRem) }
}

@Composable
fun SvgAbout(modifier: Modifier) {
    Svg(attrs = modifier.maxHeight(420.px).toAttrs {
        this.viewBox(0, 0, 800, 600)
        width(800)
        height(600)
    }) {
        val gradient1 = SvgId("gradient1")

        Defs {
            LinearGradient(gradient1) {
                Stop(0.percent, Color.rgb(0x7F00FF))
                Stop(100.percent, Color.rgb(0xFF00B3))
            }
        }
        val gradient2 = SvgId("gradient2")
        Defs {
            LinearGradient(gradient2) {
                Stop(0.percent, Color.rgb(0x00CFFF))
                Stop(100.percent, Color.rgb(0x00BFFF))
            }
        }
        Circle {
            cx(200)
            cy(300)
            r(120)
            fillOpacity(0.6)
            fill(gradient1)
        }
        Circle {
            cx(600)
            cy(200)
            r(100)
            fillOpacity(0.6)
            fill(gradient2)
        }
        Polygon {
            points(400 to 100, 450 to 200, 350 to 200)
            fill(Color.rgb(0xFF6F61))
            fillOpacity(0.7)
        }
        Polygon {
            points(300 to 400, 500 to 400, 400 to 500)
            fill(Color.rgb(0x6A5ACD))
            fillOpacity(0.7)
        }
        Path {
            d(
                "M150 450 Q250 350 350 450 T550 450"
            )
            stroke(Color.rgb(0xFFCC00))
            strokeWidth(5)
            fillOpacity(0)
            strokeOpacity(0.8)
        }
        Path {
            d(
                "M650 350 C700 300 750 400 650 450"
            )
            stroke(Color.rgb(0x00FF7F))
            strokeWidth(5)
            fillOpacity(0)
            strokeOpacity(0.8)
        }
    }
}

@Composable
fun PageLayout(title: String, content: @Composable ColumnScope.() -> Unit) {
    LaunchedEffect(title) {
        document.title = "Kobweb - $title"
    }

    Box(
        Modifier
            .fillMaxWidth()
            .minHeight(100.percent)
            // Create a box with two rows: the main content (fills as much space as it can) and the footer (which reserves
            // space at the bottom). "min-content" means the use the height of the row, which we use for the footer.
            // Since this box is set to *at least* 100%, the footer will always appear at least on the bottom but can be
            // pushed further down if the first row grows beyond the page.
            // Grids are powerful but have a bit of a learning curve. For more info, see:
            // https://css-tricks.com/snippets/css/complete-guide-grid/
            .gridTemplateRows { size(1.fr); size(minContent) },
        contentAlignment = Alignment.Center
    ) {
//        SvgCobweb(Modifier.gridRow(1).align(Alignment.TopStart))
        Column(
            // Isolate the content, because otherwise the absolute-positioned SVG above will render on top of it.
            // This is confusing but how browsers work. Read up on stacking contexts for more info.
            // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_positioned_layout/Understanding_z-index/Stacking_context
            // Some people might have used z-index instead, but best practice is to avoid that if possible, because
            // as a site gets complex, Z-fighting can be a huge pain to track down.
            Modifier.fillMaxSize().gridRow(1),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            NavHeader()
            Div(PageContentStyle.toAttrs()) {
                content()
            }
        }
        // Associate the footer with the row that will get pushed off the bottom of the page if it can't fit.
        Footer(Modifier.fillMaxWidth().gridRow(2))
    }
}
