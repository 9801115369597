import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.BasePath
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.navigation.remove
import com.varabyte.kobweb.silk.defer.DeferringHost
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    AppGlobals.initialize(mapOf("title" to "About"))
    BasePath.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { ru.workinprogress.about.pages.HomePage() }
        ctx.router.register("/about") { ru.workinprogress.about.pages.AboutPage() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerStyle("subheadline-text", ru.workinprogress.about.SubheadlineTextStyle)
        ctx.theme.registerStyle("markdown",
                ru.workinprogress.about.components.layouts.MarkdownStyle)
        ctx.theme.registerStyle("page-content",
                ru.workinprogress.about.components.layouts.PageContentStyle)
        ctx.theme.registerStyle("footer", ru.workinprogress.about.components.sections.FooterStyle)
        ctx.theme.registerStyle("nav-header",
                ru.workinprogress.about.components.sections.NavHeaderStyle)
        ctx.theme.registerStyle("hero-container", ru.workinprogress.about.pages.HeroContainerStyle)
        ctx.theme.registerStyle("home-grid", ru.workinprogress.about.pages.HomeGridStyle)
        ctx.theme.registerStyle("home-grid-cell", ru.workinprogress.about.pages.HomeGridCellStyle)
        ctx.theme.registerVariant("-circle", ru.workinprogress.about.CircleButtonVariant
        )
        ctx.theme.registerVariant("-uncolored", ru.workinprogress.about.UncoloredButtonVariant
        )
        ctx.theme.registerKeyframes("side-menu-slide-in",
                ru.workinprogress.about.components.sections.SideMenuSlideInAnim)
        ru.workinprogress.about.initColorMode(ctx)
        ru.workinprogress.about.initSiteStyles(ctx)
        ru.workinprogress.about.initTheme(ctx)
    }

    router.tryRoutingTo(BasePath.remove(window.location.href.removePrefix(window.origin)),
            UpdateHistoryMode.REPLACE)

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    renderComposable(rootElementId = "root") {
        ru.workinprogress.about.AppEntry {
            router.renderActivePage { DeferringHost { it() } }
        }
    }
}
