package ru.workinprogress.about.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/")
@Composable
fun AboutPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("About.md", mapOf())) {
        ru.workinprogress.about.components.layouts.MarkdownLayout("About") {
            org.jetbrains.compose.web.dom.H1(attrs = { id("about") }) {
                org.jetbrains.compose.web.dom.Text("About")
            }
        }
    }
}
